// In StoreCard.js
import React from 'react';
import './StoreCard.css'; // Import CSS for styling

function StoreCard({ store }) {
  return (
    <div className="store-card aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-white xl:aspect-h-8 xl:aspect-w-7">
      <img className="store-logo h-full w-full object-cover object-center group-hover:opacity-75" src={store.logoURL} alt={store.name} />
      <h3 className="mt-4 text-sm text-gray-500">{store.walletName}</h3>
      <p className='mt-1 text-lg font-bold text-gray-700'>{store.chainName}</p>
      <p className='mt-1 text-sm text-gray-700'>{store.discount}% הנחה</p>
    </div>
  );
}

export default StoreCard;
