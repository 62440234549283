import React, { useRef, useState } from 'react'
import './SearchBar.css'


export default function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef();

  const handleSubmit = (event) => {
    console.log('submit');
    event.preventDefault();
    onSearch(searchTerm);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className="search-bar-container">
      <form className="search-bar" action='#' onSubmit={handleSubmit}>
        <label htmlFor="default-search" className="text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input ref={inputRef} type="search" value={searchTerm} onBlur={() => onSearch(searchTerm)} onChange={(e) => setSearchTerm(e.target.value)} id="default-search" className="block w-full p-4 ps-10 text-mm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="חיפוש..." />
          <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">חפש</button>
        </div>
      </form>
    </div>
  );
}
