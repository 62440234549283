import React from 'react';
import './App.css';
import Footer from './components/Footer';
import StoreGrid from './components/StoreGrid';
import logo from './logo.svg';


function App() {
  return (
    <div className="app bg-gray-200">
      <div className="app-container">
        <header className="app-header">
          <a href="/"><img src={logo} className="app-logo" alt="logo" /></a>
          <p>האתר הזה עוזר לך למצוא את כל ההנחות של כרטיס בהצדעה הנטען</p>
        </header>
        <StoreGrid />
      </div>
      <Footer />
    </div>
  );

}

export default App;
