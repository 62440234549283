import React, { useMemo, useState } from 'react';
import { useFetchData } from '../hooks/useFetchData';
import { getStores } from '../services/storesService';
import SearchBar from './SearchBar';
import StoreCard from './StoreCard';
import './StoreGrid.css';


export default function StoreGrid() {
  const [stores, loading, error] = useFetchData(getStores);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredStores = useMemo(() => {
    console.log('Search term changed:', searchTerm);
    if (!stores) {
      return [];
    }

    if (!searchTerm) {
      return stores;
    }
    return stores.map((category) => {
      let obj = Object.assign({}, category);
      obj.stores = category.stores.filter((store) => store.chainName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.walletName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.category.toLowerCase().includes(searchTerm.toLowerCase()));
      return obj
    });
  }, [searchTerm, stores]);

  if (loading) {
    return <p>טוען...</p>;
  }
  if (error) {
    return <p>אופס... הייתה בעיה בטעינת הדף.</p>;
  }

  const isNoResults = filteredStores.every((category) => category.stores.length === 0);
  return (
    <div className="store-grid-container">
      <SearchBar onSearch={setSearchTerm} />
      <p className="pt-4 text-sm text-gray-600">לאתר אין כל קשר לאתר <a className="ont-medium  dark:text-blue-400 hover:underline" href="https://behatsdaa.org.il">בהצדעה</a> וייתכן כי המידע שמוצג בו אינו מעודכן או אינו מדוייק</p>
      {isNoResults ? <p className="no-results">לא נמצאו תוצאות</p> :
        filteredStores.map((category) =>
          category.stores.length > 0 &&
          (
            <div key={category.name} className="mx-auto max-w-2xl px-4 py-8 sm:px-6 lg:max-w-7xl lg:px-8 justify-items-center">
              <h1 className="text-2xl font-bold">{category.name}</h1>
              <div className="mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                {category.stores.map((store) => (
                  <StoreCard key={store.chainName + store.walletName + category.name} store={store} />
                ))}
              </div>
            </div>
          )
        )}
    </div>
  );
}

