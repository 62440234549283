import { useEffect, useState } from "react";

export function useFetchData(url) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response;
                if (typeof url === 'string') {
                    response = await fetch(url);
                    response = await response.json();
                } else if (typeof url === 'function') {
                    response = await url();
                } else {
                    throw new Error('Invalid URL type');
                }
                
                setData(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return [data, loading, error];
}
