export async function getStores() {
  let cards = await (await fetch('/cards.json')).json();
  cards = cards.data.wallets;
  let res = await Promise.all(cards.flatMap(async (card) => {
    let walletChainData = await (await fetch(`/${card.walletID}.json`)).json();
    return walletChainData.data.map((category) => {
      return {
        name: category.tagName,
        stores: category.walletChainData.flatMap((store) => {
          store.walletName = card.walletName;
          store.category = category.tagName;
          store.discount = card.discountRate;
          return store;
        })
      }
    });
  }));

  res = res.flat();

  // Merge categories with the same name
  let mergedCategories = [];
  res.forEach((category) => {
    let existingCategory = mergedCategories.find((c) => c.name === category.name);
    if (existingCategory) {
      existingCategory.stores.push(...category.stores);
    } else {
      mergedCategories.push(category);
    }
  });

  return mergedCategories;
}