
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <p>נוצר על ידי <a className="ont-medium  dark:text-blue-400 hover:underline" href="https://github.com/gkpln3">גיא קפלן</a></p>
            <p>לאתר אין כל קשר לאתר <a className="ont-medium  dark:text-blue-400 hover:underline" href="https://behatsdaa.org.il">בהצדעה</a> המתוחזק ע״י נופשונית בע״מ</p>
        </footer>
    );
};

export default Footer;
